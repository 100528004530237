import React from 'react';
import ReactDOM from 'react-dom';
import querystring from 'querystring';

let parsedQuery = {};

if (window.location.search.length > 1) {
	parsedQuery = querystring.parse(window.location.search.substr(1));
}

if (parsedQuery.screen === 'borrowerLookup') {
	window.location = '/borrowerLookup.html';
}

const getCookieValue = (name) => (
	document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

function deleteCookie(sKey, sPath, sDomain) {
    document.cookie = encodeURIComponent(sKey) + 
                  "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + 
                  (sDomain ? "; domain=" + sDomain : "") + 
                  (sPath ? "; path=" + sPath : "");
}


class LoginApp extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			emailInput: '',
			passwordInput: '',
			error: '',
			authStage: '',
			authCode: '',
			busy: false,
		}
	}

	startOver = () => {
		this.setState({
			emailInput: '',
			passwordInput: '',
			error: '',
			authStage: '',
			authCode: '',
		});
	}
	componentDidMount() {
		// TODO: do we need this?
		window.document.title = 'Correspondent Access';
	}
	changeEmailInput = (e) => {
		this.setState({emailInput: e.target.value});
	}
	changePasswordInput = (e) => {
		this.setState({passwordInput: e.target.value});
	}
	changeAuthCode = (e) => {
		this.setState({authCode: e.target.value});
	}
	changeTrusted = (e) => {
		let newTrust = ! this.state.trustedDevice;
		this.setState({ trustedDevice: newTrust });
	}

	resetTrust = () => {
		deleteCookie('trustedDevice');
		this.forceUpdate();
	}

	submit = (e) => {
		e.preventDefault();

		this.setState({
			error: '',
			busy: true,
		});
		
		$.ajax({
			url: '/app/login',
			method: 'POST',
			data: {
				email: this.state.emailInput,
				password: this.state.passwordInput
			},
			error: (xhr, status, error) => {
				this.setState({
					error: error,
					busy: false,
				});
			},
			success: (response) => {
				console.log(response);
				if (response.ok) {
					window.location = '/app.html';
				}
				else if (response.needsBetterPassword) {
					window.location = response.resetPasswordUrl;
				}
				else if (response.authStage) {
					this.setState({
						trustedDevice: true,
						authStage: response.authStage,
						error: response.message || '',
						busy: false,
					});
				}
				else {
					this.setState({
						error: response.message || 'Invalid username or password',
						busy: false,
					});
				}
			}
		});
	}

	submitWith2fa = (e) => {
		e.preventDefault();

		this.setState({
			error: '',
			busy: true,
		});

		$.ajax({
			url: '/app/login',
			method: 'POST',
			data: {
				email: this.state.emailInput,
				password: this.state.passwordInput,
				authCode: this.state.authCode,
				trustedDevice: this.state.trustedDevice,
			},
			error: (xhr, status, error) => {
				this.setState({
					error: error,
					busy: false,
				});
			},
			success: (response) => {
				console.log(response);
				if (response.ok) {
					window.location = '/app.html';
				}
				else if (response.needsBetterPassword) {
					window.location = response.resetPasswordUrl;
				}
				else if (response.pwStage) {
					this.setState({
						authStage: false,
						busy: false,
					});
				}
				else {
					this.setState({
						error: response.message || 'Invalid security code',
						busy: false,
					});
				}
			}
		});
	}

	render() {

		// 2FA Security code login

		if (this.state.authStage) {

			let busy = !! this.state.busy;
			let icon = "fa fa-lg fa-key";
			if (busy) icon = "fa fa-spinner fa-pulse fa-lg fa-fw";

			let msg = '';
			if (this.state.error) {
				msg = <p style={{ fontWeight: 'bold', fontSize: 16, marginTop: 10, color: "#00a" }}>{this.state.error}</p>;
			}

			const style = {
				width: 200,
				fontSize: 19,
				marginLeft: 8,
				marginRight: 8,
				verticalAlign: 'middle',
			}

			return (
				<div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
					<div className="card" style={{width: 500}}>
						<div className="card-body">
							<h5 className="card-title">
								Two Factor Authentication
							</h5>
							<p className="card-text">
								Please enter your security code here to continue.
							</p>
							<i className={icon} aria-hidden="true" style={{ verticalAlign: 'middle', color: "#6495ed" }}></i>

							<form onSubmit={this.submitWith2fa}>

								<input
									type="text"
									disabled={busy}
									autoFocus={true}
									name='confirmCodeField'
									autoComplete="off"
									style={style}
									value={this.state.authCode}
									onChange={this.changeAuthCode} />
									
								&nbsp;

								<button
									disabled={busy}
									type="submit"
									className="btn btn-primary">
									Verify Code
								</button>

								&nbsp;

								<button
									disabled={busy}
									onClick={this.startOver}
									className="btn btn-default">
									Start Over
								</button>

								<label className="form-check-label" style={{ marginTop: 12, marginLeft: 22 }}>
									<input
										type="checkbox"
										name='trustedDevice'
										autoComplete="off"
										className="form-check-input"
										checked={this.state.trustedDevice}
										onChange={this.changeTrusted}
									/>
										Don't ask again on this device for 30 days
								</label>

							</form>

							{msg}

						</div>
					</div>
				</div>
			);

		}

		// Regular email login

		else {

			let removeTrusted = "";
			var linkStyle = { color: '#007bff', cursor: 'pointer' };
			let c = getCookieValue('trustedDevice');
			if (c) removeTrusted = (
				<p className="card-text" style={{marginTop: 15}}>This is a trusted device. <a onClick={this.resetTrust} style={linkStyle}> Click here to reset.</a></p>
			);

			return (
				<div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
					<div className="card" style={{width: 500}}>
						<div className="card-body">
							<h5 className="card-title">
								Correspondent Login
							</h5>
							<p className="card-text">Please enter your email and password.</p>
							<form onSubmit={this.submit}>
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										placeholder="Email"
										value={this.state.emailInput}
										onChange={this.changeEmailInput}/>
								</div>
								<div className="form-group">
									<input
										type="password"
										className="form-control"
										placeholder="Password"
										value={this.state.passwordInput}
										onChange={this.changePasswordInput}/>
								</div>
								<button
									type="submit"
									className="btn btn-primary">
									Submit
								</button>
								<p className="text-danger" style={{marginTop: 15}}>{this.state.error}</p>
							</form>
							{removeTrusted}
							<p className="card-text" style={{marginTop: 15}}>Forgot your password? <a href="/resetPassword.html">Click here to reset it.</a></p>
						</div>
					</div>
				</div>
			);
		
		}

	}
}

class pwLogin extends React.Component {


}



ReactDOM.render(<LoginApp/>, document.getElementById('app'));